import React from "react"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import CommentsFB from "../../components/Comments"
import EfemeridesM from "../../components/efemeridesm"

const Efemerides2 = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides",
        title: "Efemérides | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />

    <h1>Conozca Salta a través de sus Efemérides</h1>
    {/* Conozca Salta a través de sus Efemérides – 1993 */}
    <EfemeridesM />
    <div className="prologo">
      <img
        data-sizes="auto"
        className="lazyload blur-up"
        src="https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg"
        data-src="https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg"
        alt="Tapa del libro del libro Conozca Salta a través de sus Efemérides"
      />
      <p>
        <strong>Prólogo</strong>.
      </p>
      <p>
        Presentamos este humilde trabajo, como un aporte que ayude a conocer la
        historia de Salta.
      </p>
      <p>
        Se escogió como método de presentarlo a través de las Efemérides, ya que
        de ese modo se estimularía la breve y diaria lectura.
      </p>
      <p>
        Se recomienda este material en forma especial a los periodistas, para
        que incluyan en su trabajo diarias noticias de Salta y su historia.
      </p>
      <p>
        A los maestros y profesores que forman a niños y jóvenes, volcando en
        ellos el amor por su tierra.
      </p>
      <p>
        A los padres de familia, para que lo lean en casa, entre todos, si fuera
        posible en voz alta, para luego comentar y recordar las cosas de Salta.
      </p>
      <p>
        Este material se juntó, luego de muchos años de lectura y recuerda los
        hechos ocurridos en nuestra provincia de Salta.
      </p>
      <p>
        La idea fue trabajar en próximas ediciones con más datos y agrandando la
        región geográfica a la que se recuerda a través de su historia.
      </p>
      <p>
        Que las páginas de este libro, le ayuden a descubrir el rostro de Salta
        y que le estimule el amor por esta tierra.
      </p>
      <p>
        <strong>El Autor.</strong>
      </p>
    </div>
    <CommentsFB />
  </Layout>
)

export default Efemerides2
